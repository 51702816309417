import React, { FC } from "react";
import { OfferTemplate } from "@templates/OfferTemplate";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { UniversalSection } from "@components/molecules/UniversalSection";
import { ChildImageSharp } from "@interfaces/childImageSharp";
import { WhyUsSection } from "@components/molecules/WhyUsSection";
import WhatWeCanDoMore from "@components/organisms/WhatWeCanDoMore";

const Investment: FC<Props> = ({ data }) => {
  const { t } = useTranslation("offer");
  const { t: ct } = useTranslation("common");

  return (
    <OfferTemplate
      title={ct("title-offer-marketing-campaigns")}
      description={ct("description-offer-marketing-campaigns")}
      heroTitle={t("marketing-campaigns.hero.title")}
      heroDescription={t("marketing-campaigns.hero.description", {
        returnObjects: true,
      })}
      heroImage={data.heroImage.childImageSharp.gatsbyImageData}
    >
      <UniversalSection
        description={t("marketing-campaigns.sections.0.description", {
          returnObjects: true,
        })}
        title={t("marketing-campaigns.sections.0.title")}
        images={[data.firstSectionImage.childImageSharp.gatsbyImageData]}
        imageOptions={{
          objectFit: "contain",
        }}
        fromRight
      />
      <UniversalSection
        description={t("marketing-campaigns.sections.1.description", {
          returnObjects: true,
        })}
        title={t("marketing-campaigns.sections.1.title")}
        images={[data.secondSectionImage.childImageSharp.gatsbyImageData]}
      />

      <UniversalSection
        description={t("marketing-campaigns.sections.2.description", {
          returnObjects: true,
        })}
        title={t("marketing-campaigns.sections.2.title")}
        images={[data.thirdSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
      />

      <UniversalSection
        description={t("marketing-campaigns.sections.3.description", {
          returnObjects: true,
        })}
        title={t("marketing-campaigns.sections.3.title")}
        images={[data.fourthSectionImage.childImageSharp.gatsbyImageData]}
      />

      <UniversalSection
        description={t("marketing-campaigns.sections.4.description", {
          returnObjects: true,
        })}
        title={t("marketing-campaigns.sections.4.title")}
        images={[data.fifthSectionImage.childImageSharp.gatsbyImageData]}
        fromRight
      />

      <UniversalSection
        description={t("marketing-campaigns.sections.5.description", {
          returnObjects: true,
        })}
        title={t("marketing-campaigns.sections.5.title")}
        images={[data.sixthSectionImage.childImageSharp.gatsbyImageData]}
      />

      <WhatWeCanDoMore exclude={3} />
      <WhyUsSection />
    </OfferTemplate>
  );
};

interface Props {
  data: {
    firstSectionImage: ChildImageSharp;
    secondSectionImage: ChildImageSharp;
    thirdSectionImage: ChildImageSharp;
    fourthSectionImage: ChildImageSharp;
    fifthSectionImage: ChildImageSharp;
    sixthSectionImage: ChildImageSharp;
    heroImage: ChildImageSharp;
  };
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    heroImage: file(name: { eq: "offer_marketing-campaigns-hero" }) {
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }

    firstSectionImage: file(name: { eq: "offer_marketing-campaigns_0" }) {
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }

    secondSectionImage: file(name: { eq: "offer_marketing-campaigns_1" }) {
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }

    thirdSectionImage: file(name: { eq: "offer_marketing-campaigns_2" }) {
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    fourthSectionImage: file(name: { eq: "offer_marketing-campaigns_3" }) {
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    fifthSectionImage: file(name: { eq: "offer_marketing-campaigns_4" }) {
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
    sixthSectionImage: file(name: { eq: "offer_marketing-campaigns_5" }) {
      childImageSharp {
        gatsbyImageData(quality: 95)
      }
    }
  }
`;

export default Investment;
